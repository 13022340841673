<template>
  <section>
    <v-data-table
      item-key="groupId"
      show-expand
      :expanded.sync="expanded"
      :loading="isLoading"
      :headers="displayedHeader"
      :items="items"
      :server-items-length="totalData"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      @item-expanded="fetchDetail"
    >
      <template v-slot:[`item.createdAt`]="{item}">
        <span>{{ dateFormat(item.createdAt) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-tooltip top v-if="userAccess.canUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              icon
              class="mr-2"
              color="primary"
              @click="() => showDialogUpdateShipment(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.EDIT}}</span>
        </v-tooltip>
      </template>
      <template class="d-none" v-slot:expanded-item="{ headers, item }">
        <ExpandedItemList
          :parentMenuId="item.id"
          :isLoading="item.fetchingDetail"
          :items="item.child"
          :colspanLength="displayedHeader.length"
          @fetchData="fetchData"
        />
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          List Pesanan Tolakan
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
    <DialogEditShipment
      ref="dialogEditShipment"
      @fetchData="fetchData"
    />
  </section>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

import ExpandedItemList from './ExpandedItemList.vue';
import DialogEditShipment from './Dialog/UpdateShipment.vue';

export default {
  name: 'table-list-role',
  components: {
    ExpandedItemList,
    DialogEditShipment,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: 'No Plat',
          value: 'vehicleNo',
          width: '15%',
        },
        {
          text: 'Pesanan Dibuat',
          value: 'createdAt',
          width: '25%',
        },
        {
          text: 'Ket Tarif',
          value: 'serviceType',
          width: '10%',
        },
        {
          text: 'Alasan',
          value: 'statusDescription',
          width: '35%',
        },
        {
          text: 'Action',
          value: 'actions',
          width: '25%',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          cellClass: 'clickable',
        },
      ],
      expanded: [],
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (!canUpdate) {
          return header.value !== 'actions';
        }
        return header;
      });
    },
  },
  methods: {
    dateFormat,
    async showDialogUpdateShipment(item) {
      if (!item.child) await this.fetchDetail({ item, isExpanded: false });
      if (item.transportTypeId) {
        this.$refs.dialogEditShipment.transportTypeId = item.transportTypeId;
        this.$refs.dialogEditShipment.itemsTypeVehicle = [{
          ...this.$refs.dialogEditShipment.itemsTypeVehicle,
          name: item.transportTypeName,
          id: item.transportTypeId,
        }];
      }
      this.$refs.dialogEditShipment.groupId = item.groupId;
      this.$refs.dialogEditShipment.items = item.child.map((i, idx) => ({
        ...i,
        sequenceNo: i.sequenceNo || idx + 1,
      }));
      this.$refs.dialogEditShipment.dialog = true;
    },
    async fetchDetail({ item, isExpanded = true }) {
      if (item.child) return;
      try {
        if (isExpanded) this.$set(item, 'fetchingDetail', true);
        if (!isExpanded) this.$root.$loading.show();
        const result = await this.$_services.oracleData.getOrcleOrdersDetail({ groupId: item.groupId });
        this.$set(item, 'child', result.data);
        return result;
      } finally {
        this.$delete(item, 'fetchingDetail');
        this.$root.$loading.hide();
      }
    },
    async fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filter = skipEmptyStringObject({
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
        ...this.filter,
      });
      try {
        this.isLoading = true;
        const result = await this.$_services.rejectedOrders.fetchRejectedOrders({ filter });
        this.items = result.data.contents;
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
